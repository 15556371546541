<template>
  <div v-if="getRoute ==='printer'"  class="size-div mr-4">
      <div  @click="openModal(item._id)" 
      class="button-style mt-5 pt-4 pl-5" 
      block outlined v-for="item in getHardware.printers" :key="item._id"
      >
      <v-row>
        <v-col class="mb-2" xl="11" md="11"><p  class="data-left">{{ item._id }}</p></v-col>
        <v-col class="mb-2" xl="1" md="1"><p class="data-right pa-1 mr-5">{{ item.count }}</p></v-col>
      </v-row>   
      </div>
  </div>
  <div v-else  class="size-div mr-4">
      <div  @click="openModal(item._id)" 
      class="button-style mt-5 pt-4 pl-5" 
      block outlined v-for="item in getHardware.summary" :key="item._id"
      >
      <v-row>
        <v-col class="mb-2" xl="11" md="11"><p  class="data-left">{{ item._id }}</p></v-col>
        <v-col class="mb-2" xl="1" md="1"><p class="data-right pa-1 mr-5">{{ item.count }}</p></v-col>
      </v-row>   
      </div>
  </div>
</template>

<script>
import FeedPeripheralInfo from '../components/FeedPeripheralInfo.vue'
import { mapGetters } from 'vuex'
export default {
    name: FeedPeripheralInfo,

    data () {
    return {
        info: null,
        currentId: "",
        currentRoute: "",
  }
},

  computed:{
    ...mapGetters({'getRoute':'hardware/getRoute', 'getHardware':'hardware/getHardware', getHardwareData: 'hardware/getDataHardware'}),
  },

 methods: {
    openModal (value) {
      this.$store.commit('hardware/SET_HARDWARE_DATA', true);
      this.$store.commit('hardware/SET_CURRENT_HARDWARE', value);
      this.$store.dispatch('hardware/getCurrentHardware', this.getRoute, value);
      this.currentId = value;
    },
    current_Route(){
      this.currentRoute = this.getRoute;
    }
  
  },
  mounted(){
      this.getHardware(this.getRoute);
  }
  
 

}


</script>

<style>
.button-style{
   word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  line-clamp: 5;
  -webkit-box-orient: vertical;
  cursor: pointer;
  height: 54px;
  left: 132px;
  top: 595px;
  background: #FFFFFF;
  border: 1px solid #DEDEDE;
  border-radius: 4px;
}

.size-div{
    width: 100%;
    overflow-y: scroll;
    height: 60vh;
}
.size-div::-webkit-scrollbar {
      display: none;
}
.data-left{
  float: left;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #222222;
}
.data-right{
  float: right;
  background: #E4E5E6;
  border-radius: 4px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #222222;

}

</style>