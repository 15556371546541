<template>
 <v-card 
    class="card mt-0" 
    height="100vh"
    min-width="35vh" 
    min-height="100vh"
    elevation="1"
    >
    <v-col>
        <v-row class="search-bar ma-0 pt-7 pl-4 pr-4">
        <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="dates"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
           <v-combobox
            v-model="dates"
            outlined
            small-chips
            multiple
            dense
            class="input-bar ma-0"
            label="Pick specific date range"
            append-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          >
        </v-combobox>
        </template>
        <v-date-picker
          v-model="dates"
          multiple
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu = false"
          >Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save(dates)"
          >OK
          </v-btn>
        </v-date-picker>
      </v-menu>
        </v-row>
        <v-row class="search-bar ma-0 pt-11 pl-4 pr-4">
        <v-text-field 
          outlined
          dense
          class="ma-0"
          placeholder="Search"
          append-icon="mdi-magnify"
          @input="
            (val) => {this.searchQuery = val;}"
          >
        </v-text-field>
        </v-row>
    </v-col>
    <v-col class="pt-12">
        <v-row class="text-components ma-0 pt-10 pl-4">
            <span>List of {{ this.getRoute }}s {{ getNumberData }}</span>
        </v-row>
    </v-col>
    <v-col class="scrollbar" max-height="600">
        <v-row class="ma-0 pl-4">
            <FeedPeripheralInfo
                :items="getHardware"
            />
    </v-row>
    </v-col>
 </v-card>
</template>

<script>
import FeedPeripheralInfo from '../../components/FeedPeripheralInfo.vue'
import { mapActions, mapGetters } from 'vuex'
import SearchApi from '../../services/Api'
export default {
    name: 'PeriperalInfo',
    components: {
        FeedPeripheralInfo
    },
    data () {
        return {
           searchQuery: "",
           loadingHardware: false,
           dates: [],
           unixDates: [],
           interval: "",
           menu: false,
        }
    },

    computed: {
    ...mapGetters({'getHardware':'hardware/getHardware', 'getCurrentHardware':'hardware/getCurrentHardware', 'getRoute':'hardware/getRoute'}),
            getRoute(){
                let route = this.$route.path.split('/');
                return route[2].toUpperCase();
            }
        
    },
    methods:{
    ...mapActions({'getHardwareAction':'hardware/getHardware', 'getRelatedReports':'hardware/getRelatedReports'}),
    runSearch() {
        this.loadingHardware = true;
      if (this.searchQuery === "") {
        this.getHardwareAction(this.getRoute);
        this.loadingHardware = false;
      } else {
        SearchApi.searchHardware(this.getRoute, this.searchQuery)
            .then((response) => {
              if (response.status === 200) {
                this.$store.commit('hardware/SET_HARDWARE', response.data.summary);
              }
            })
            .catch((err) => {
              this.loadingAccounts = false;
              this.$store.commit("updateAlert", {
                type: "error",
                shown: true,
                message: `Search failed. Check console.`,
              });
              console.log(err);
            });
      }
    }, 
    convertToUnix(){
        if(this.dates.length === 2){
            for(let i = 0; i < this.dates.length; i++){
                this.unixDates.push(new Date(this.dates[i]).getTime());
            }
            this.interval = this.unixDates.join(' ');
            this.$store.commit("hardware/SetDateInterval", this.interval);
        }
    }, 
    getReportsByPeriod(){
        if(this.dates.length === 2){
            this.getRelatedReports();
        }
    }
   
    },
    //hsha
    watch: {
    searchQuery() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.runSearch();
      },100);
    },
    dates(){
        this.convertToUnix();
    },
    unixDates(){
        this.getReportsByPeriod();  
    }
  },

  mounted() {
    if(!this.getHardware.length){
      this.getAllHardware();
    }

  },
   
    
}
</script>

<style>

.search-bar {
    height: 40px;
  }
.text-components {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #222222;
}




</style>