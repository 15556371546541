<template>
  <v-card 
  v-show="openModal"
  class="pa-12" height="100vh"
  >
    <v-row class="mb-10">
      <v-col ><h2>{{ currentId }}</h2></v-col>
      <v-col>
         <v-btn
              icon
              @click="hideSlip()"
              class="close-btn"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-10 mb-10">
      <v-col>
        <span class="reports-heading mb-4">Structure of Related Reports</span>
      <div id="chart" >
        <apexchart type="pie" width="380" :options="chartOptions" :series="series"></apexchart>
      </div>
      </v-col>
    </v-row>
     <v-row>
      <v-col><h3>Related Reports</h3></v-col>
    </v-row>
     <div class="size-div2">
      <v-row class="pb-10 pt-10">
      <v-col
      v-for="item in getDataHardware.device_details.related_reports" 
      :key="item"
        xl="4"
        lg="4"
        md="9"
      >
        <v-container class="report-card">
          <v-col class="avatar pa-2">
             <v-avatar :color="item.overall_result === 'PASSED' ? 'primary' : 'error'" size="30" tile class="icon">
             <v-icon color="#ffffff" size="24">mdi-file-document</v-icon>
        </v-avatar>
          </v-col>
          <v-col class="pa-4">
            <v-row>
              <span class="card-content">{{item.report_id}}</span>
            </v-row>
            <v-row>
              <span class="card-content-text">{{beautifyDate(item.test_timestamp * 1000)}}</span>
            </v-row>
          </v-col>
        </v-container>
      </v-col>
    </v-row>
    </div>
    

    </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VueApexCharts from 'vue-apexcharts'
import { format } from 'date-fns'
export default {
  components: {
   apexchart: VueApexCharts,
  },
  data () {
    return {
        dataInfo: {},
       
    }
  },
  computed: {
    ...mapGetters({
      series: 'hardware/getSeries',
      chartOptions: 'hardware/getChartOptions',
      openModal: 'hardware/getHardwareData',
      currentId: 'hardware/getCurrentHardware',
      getDataHardware: 'hardware/getDataHardware',
    })
  },
  methods: {
    ...mapActions({
      getCurrentHardware: 'hardware/getCurrentHardware',
    }),
    hideSlip () {
     this.$store.commit('hardware/SET_HARDWARE_DATA', false);
    },
    beautifyDate(date) {
      return date ? format(new Date(date), 'dd MMM yyyy HH:mm:ss') : "";
    },
  
  },

}
</script>

<style>
.size-div2{
    width: 100%;
    overflow-y: scroll;
    height: 40vh;
}
.size-div2::-webkit-scrollbar {
      display: none;
}

.reports-heading{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #7A7A7A;
}
.close-btn{
  float: right;
}
.report-card{
  box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
align-items: flex-start;
padding: 8px;
gap: 8px;

width: 318px;
height: 48px;

background: #FFFFFF;
border: 1px solid #DEDEDE;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 1;
}
.card-content{
  width: 262px;
  height: 16px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;

  color: #222222;


  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.card-content-text{
  width: 262px;
  height: 14px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #7A7A7A;


/* Inside auto layout */

flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;
}
.avatar{
  box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px;

width: 32px;
height: 32px;
border: 1px solid #464343;
border-radius: 4px;

/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;
}





</style>