import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{directives:[{name:"show",rawName:"v-show",value:(_vm.openModal),expression:"openModal"}],staticClass:"pa-12",attrs:{"height":"100vh"}},[_c(VRow,{staticClass:"mb-10"},[_c(VCol,[_c('h2',[_vm._v(_vm._s(_vm.currentId))])]),_c(VCol,[_c(VBtn,{staticClass:"close-btn",attrs:{"icon":""},on:{"click":function($event){return _vm.hideSlip()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1),_c(VRow,{staticClass:"mt-10 mb-10"},[_c(VCol,[_c('span',{staticClass:"reports-heading mb-4"},[_vm._v("Structure of Related Reports")]),_c('div',{attrs:{"id":"chart"}},[_c('apexchart',{attrs:{"type":"pie","width":"380","options":_vm.chartOptions,"series":_vm.series}})],1)])],1),_c(VRow,[_c(VCol,[_c('h3',[_vm._v("Related Reports")])])],1),_c('div',{staticClass:"size-div2"},[_c(VRow,{staticClass:"pb-10 pt-10"},_vm._l((_vm.getDataHardware.device_details.related_reports),function(item){return _c(VCol,{key:item,attrs:{"xl":"4","lg":"4","md":"9"}},[_c(VContainer,{staticClass:"report-card"},[_c(VCol,{staticClass:"avatar pa-2"},[_c(VAvatar,{staticClass:"icon",attrs:{"color":item.overall_result === 'PASSED' ? 'primary' : 'error',"size":"30","tile":""}},[_c(VIcon,{attrs:{"color":"#ffffff","size":"24"}},[_vm._v("mdi-file-document")])],1)],1),_c(VCol,{staticClass:"pa-4"},[_c(VRow,[_c('span',{staticClass:"card-content"},[_vm._v(_vm._s(item.report_id))])]),_c(VRow,[_c('span',{staticClass:"card-content-text"},[_vm._v(_vm._s(_vm.beautifyDate(item.test_timestamp * 1000)))])])],1)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }