<template>
<div>
 <v-row class="mt-3">
      <v-col class="border-bottom px-0 py-0" >
        <v-tabs v-model="tab" @change="getHardware()">
          <v-tab active-class="selected-tab" class="px-6" to="/hardware/cpu/summary">
            {{ this.tabs[0].cpu }}
          </v-tab>
          <v-tab active-class="selected-tab" class="px-6" to="/hardware/gpu/summary">
            {{this.tabs[1].gpu}}
          </v-tab>
          <v-tab active-class="selected-tab" class="px-6" to="/hardware/touchscreen/summary">
            {{ this.tabs[2].display}}
          </v-tab>
          <v-tab active-class="selected-tab" class="px-6" to="/hardware/printer/summary">
            {{ this.tabs[3].printers }}
          </v-tab>
          <v-tab active-class="selected-tab" class="px-6" to="/hardware/card_reader/summary">
            {{ this.tabs[4].card }}
          </v-tab>
          <v-tab active-class="selected-tab" class="px-6" to="/hardware/bill/summary">
            {{ this.tabs[5].bill }}
          </v-tab>
          <v-tab active-class="selected-tab" class="px-6" to="/hardware/coin/summary">
            {{ this.tabs[6].coin }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row >
      <v-col class="scrollbar one pa-0">
        <PeriperalInfo/>
      </v-col>
      <v-col class="two pa-0">
        <HardwareReport/>
      </v-col>
    </v-row>
</div>
    
</template>

<script>
import PeriperalInfo from '../components/Hardware/PeriperalInfo.vue'
import HardwareReport from '../components/Hardware/HardwareReport.vue'
import { mapGetters } from 'vuex'
export default {
      name : 'Hardware',
      components: {
        PeriperalInfo,
        HardwareReport
      },
      data () {
        return {
          tab: null,
          tabs: [
            {
              cpu: 'CPU',
              value: 'cpu'
            },
            {
              gpu: 'GPU',
              value: 'gpu'
            },
            {
              display: 'DISPLAY',
              value: 'display'
            },
            {
              printers: 'PRINTERS',
              value: 'printers'
            },
            {
              card: 'CARD READER',
              value: 'card'
            },
            {
              bill: 'BILL ACCEPTOR',
              value: 'bill'
            },
            {
              coin: 'COIN ACCEPTOR',
              value: 'coin'
            }
          ]
           


          
  }
      },
      computed: {
        ...mapGetters({
        hardware: 'hardware/getHardware'
        
        })
      },
      methods: {
        getHardware(){
          let hardware = this.tab.split('/');
          this.$store.commit('hardware/SET_ROUTE_NAME', hardware[2])
          this.$store.commit('hardware/SET_HARDWARE_DATA', false)
          this.$store.dispatch('hardware/getHardware', hardware[2])

        }
      },
}
</script>

<style>
.selected-tab {
  color: var(--v-textColor-base) !important;
  text-transform: none !important;
}
.border-bottom {
  border-bottom: 1px solid #E0E0E0; width:110%;
}
.one {
  min-width: 35%;
  max-width: 35%;
}
.two {
  min-width: 65%;
  max-width: 65%;
}
</style>



