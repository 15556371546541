import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"card mt-0",attrs:{"height":"100vh","min-width":"35vh","min-height":"100vh","elevation":"1"}},[_c(VCol,[_c(VRow,{staticClass:"search-bar ma-0 pt-7 pl-4 pr-4"},[_c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.dates,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dates=$event},"update:return-value":function($event){_vm.dates=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VCombobox,_vm._g(_vm._b({staticClass:"input-bar ma-0",attrs:{"outlined":"","small-chips":"","multiple":"","dense":"","label":"Pick specific date range","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},'v-combobox',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VDatePicker,{attrs:{"multiple":"","no-title":"","scrollable":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v("Cancel ")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.dates)}}},[_vm._v("OK ")])],1)],1)],1),_c(VRow,{staticClass:"search-bar ma-0 pt-11 pl-4 pr-4"},[_c(VTextField,{staticClass:"ma-0",attrs:{"outlined":"","dense":"","placeholder":"Search","append-icon":"mdi-magnify"},on:{"input":function (val) {this$1.searchQuery = val;}}})],1)],1),_c(VCol,{staticClass:"pt-12"},[_c(VRow,{staticClass:"text-components ma-0 pt-10 pl-4"},[_c('span',[_vm._v("List of "+_vm._s(this.getRoute)+"s "+_vm._s(_vm.getNumberData))])])],1),_c(VCol,{staticClass:"scrollbar",attrs:{"max-height":"600"}},[_c(VRow,{staticClass:"ma-0 pl-4"},[_c('FeedPeripheralInfo',{attrs:{"items":_vm.getHardware}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }